.header {
  z-index: 5;
  color: #c9576e;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.header-view {
  display: flex;
  flex-direction: row;
}

.header-element {
  font-weight: 500;
  font-size: x-large;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor:pointer;
  margin: 20px;
  transition-duration: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-element:hover {
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 10px;
  transition: background-color 0.4s ease-in-out;
}

.header-element-mobile {
  font-weight: 500;
  font-size: large;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor:pointer;
  margin: 10px;
  transition-duration: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-element-mobile:hover {
  background-color: rgba(255, 0, 0, 0.1);
  border-radius: 10px;
  transition: background-color 0.4s ease-in-out;
}