.projects-main {
  position: relative;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: white;
  background: -moz-linear-gradient(bottom,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(70%,rgba(255,255,255,1)));
  background: -webkit-linear-gradient(bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
  background: -o-linear-gradient(bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
  background: -ms-linear-gradient(bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
  background: linear-gradient(to top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
  width: 100%;
  min-height: 400px;
  align-items: center;
  color: #6b8087;
  justify-content: center;
}

.projects-section-title {
  color: white;
  background: linear-gradient(to bottom right, #c9576e,#feeeee);
  width: 20vw;
  border-radius: 0px 20px 20px 0px;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0px;
  bottom: 0px;
  z-index: 1;
}

.p-title {
  margin-bottom: 100px;
  writing-mode: vertical-rl;
  orientation: bt;
  padding-top: 20%;
  margin-right: 40%;
  font-size: 40px;
}

.projects-list {
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.project-box {
  width: 74vw;
  background-color: #f5e6e9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  color: #6b8087;
  margin: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 20px;
  transition-duration: 0.25s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.project-box:hover {
  transform: scale(1.04);
  transition-property: transform;
  transition-timing-function: ease;
}

.project-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.project-date {
  display: flex;
  justify-self: center;
  align-items: center;
  font-weight: 500;
  font-style: italic;
  color: #d48e9c;
}

.project-description {
  display: flex;
  justify-self: center;
  align-items: center;
}