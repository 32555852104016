.about-main {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 5%;
  padding-bottom: 10%;
  padding-right: 10%;
  padding-left: 10%;
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(255,255,255,0)), color-stop(70%,rgba(255,255,255,1)));
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
  background: -o-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
  background: -ms-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%);
  opacity: 0.9;
}

.about-title {
  padding: 0px;
  margin-left: 0px;
  width: 100%;
  margin-left: 0px;
}

.about-about {
  color: #6b8087;
  font-size: 46px;
  margin: 0px;
}

.about-me {
  color: #c9576e;
  font-size: 90px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  margin-top: -55px;
}

.about-rhs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.about-text {
  color: #6b8087;
  font-size: large;
}

.about-text-mobile {
  color: #6b8087;
}