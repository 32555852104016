.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #feeeee;
}

.landing {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  display: inline-flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  width: 300px;
}

.title span {
  color: #c9576e;
}

.card {
  opacity: 0.8;
  height: 400px;
  width: 700px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background-color: white;
  border-width: 10px;
  border-color: #b7c1c4;
  border-style: double;
}

.card-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  font-size: small;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.card-contents {
  opacity: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #6b8087;
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
  font-size: xx-large;
  flex: 1;

}

.link {
  text-decoration: none;
  color: inherit;
  padding: 10px;
  display: flex;
  justify-content: center;
  transition-duration: 0.1s;
}

.link:hover {
  transform: scale(1.5);
  transition-property: transform;
  transition-timing-function: ease-in-out;
  color: #c9576e;
}

.divider {
  background-color: #6b8087;
  width: 400px;
  border-radius: 5%;
  border: none;
  height: 1.5px;
}

.me-img {
  height: 175px;
  border-radius: 50%;
  padding: 5px;
}